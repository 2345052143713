import { useSelector, useDispatch } from 'react-redux';
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  palette,
  Checkbox,
  Button,
  LoadingCircle,
} from '@m12s/component-library';
import _capitalize from 'lodash/capitalize';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';
import { useLazyQuery, useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import styled from 'styled-components';
import { spinner } from 'lib/icons';
import Routes from 'lib/utils/routes';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FLAG_KEYS } from 'lib/constants';

import { AppNav } from 'components/AppNav';
import MainLayout from 'components/Layouts/MainLayout';
import { H5 } from 'components/Text';
import { SearchInput } from 'components/SearchInput';
import { AddWorkOrderModal } from 'components/JobTable/AddWorkOrderModal';

import {
  GET_MACHINE_SCHEDULE,
  GET_WORK_ORDER_OPERATIONS,
} from 'lib/api/queries';
import { getLatestJob } from 'lib/selectors/getLatestJob';
import { getCurrentPartOperationRef } from 'lib/selectors/getCurrentPartOperationRef';
import { getCurrentWorkOrderOp } from 'lib/selectors/getCurrentWorkOrderOp';
import { getHasOpenActivitySet } from 'lib/selectors/getHasOpenActivitySet';
import { getMachine } from 'lib/selectors/getMachine';
import { getIsAPMEnabled } from 'lib/selectors/getIsAPMEnabled';
import { actionSetDefaultManualEntry } from 'lib/actions';
import { useIsLaborTicketFlow } from 'lib/hooks/useIsLaborTicketFlow';
import { getLoadingCurrentWorkOrderOp } from 'lib/selectors/getLoadingCurrentWorkOrderOp';

import EmptyMessage from './EmptyMessage';
import WorkOrderCard from './WorkOrderCard/index';
import {
  getByJobWhereClause,
  getByPartWhereClause,
  getAllWhereClause,
  searchOperations,
  getHeaderText,
} from './helpers';

const WorkOrderContainer = styled.section`
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fill, 18.625rem);
  justify-content: center;
  background-color: ${palette.Grey100};
  padding: 1rem 1rem 2rem 1rem;
  height: fit-content;
  @media (max-width: 660px) {
    grid-template-columns: 1fr;
  }
`;

const LoadMoreButton = styled(Button)`
  width: fit-content;
  margin: 0 auto 2rem auto;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  &:disabled {
    opacity: 0.3;
    :hover {
      cursor: not-allowed;
    }
  }
`;

const ControlsContainer = styled.section`
  border-top: solid 1px ${palette.Grey400};
  border-bottom: solid 1px ${palette.Grey300};
  padding: 1rem;
  display: flex;
  text-wrap: nowrap;
  align-items: center;
  gap: 1rem;
`;

const SelectWorkOrderOp = ({
  handleOnClose = () => {},
  nextStep,
  dispatch,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const queryParams = queryString.parse(history.location.search);
  const mainDispatch = useDispatch();
  const flags = useFlags();
  const editModeEnabled =
    flags[FLAG_KEYS.PRODUCTION_SCHEDULE_EDIT_MODE_ENABLED];

  const [workOrderOps, setWorkOrderOps] = useState(null);
  const [allWorkOrderOps, setAllWorkOrderOps] = useState(null);
  const [search, setSearch] = useState('');
  const [searchAllWorkOps, setSearchAllWorkOps] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [openAddWorkOrderModal, setOpenAddWorkOrderModal] = useState(false);
  const [openStartNewRunModal, setOpenStartNewRunModal] = useState(false);

  const hasOpenActivitySet = useSelector(getHasOpenActivitySet);
  const machine = useSelector(getMachine);
  const isAPMEnabled = useSelector(getIsAPMEnabled);
  const latestJob = useSelector(getLatestJob);
  const laborTicketsEnabled = useIsLaborTicketFlow();
  const loadingCurrentWorkOrderOp = useSelector(getLoadingCurrentWorkOrderOp);

  const isUpdate = !!queryParams.updateWorkOrderId;
  const isStartSetupWithNewRun = !!queryParams.activityTypeRef;

  const partOperationRef = useSelector(getCurrentPartOperationRef);
  const currentWorkOrderOp = useSelector(getCurrentWorkOrderOp);
  const currentWorkOrderOperationRef =
    currentWorkOrderOp?.workOrderOperationRef;

  const [queryStep, setQueryStep] = useState(null);
  const [loadingQueryFlow, setLoadingQueryFlow] = useState(true);

  useEffect(() => {
    if (!loadingCurrentWorkOrderOp) {
      if (editModeEnabled) {
        setQueryStep(2);
      } else if (isAPMEnabled) {
        setQueryStep(1);
      } else {
        setQueryStep(currentWorkOrderOp && !isStartSetupWithNewRun ? 1 : 2);
      }

      setLoadingQueryFlow(false);
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [loadingCurrentWorkOrderOp]);

  const getWhereClause = (step) => {
    if (isAPMEnabled) {
      switch (step) {
        case 1:
          return partOperationRef
            ? getByPartWhereClause(currentWorkOrderOp, partOperationRef)
            : getByJobWhereClause(latestJob, currentWorkOrderOperationRef);
        case 2:
          return getAllWhereClause(currentWorkOrderOperationRef);
        default:
          return null;
      }
    }

    switch (step) {
      case 1:
        return getByPartWhereClause(currentWorkOrderOp, partOperationRef);
      case 2:
        return getAllWhereClause(currentWorkOrderOperationRef);
      default:
        return null;
    }
  };

  const whereClause = !loadingCurrentWorkOrderOp && getWhereClause(queryStep);

  const [
    requestWorkOrderOperations,
    { variables: { limit = 50 } = {}, error, loading },
  ] = useLazyQuery(GET_WORK_ORDER_OPERATIONS, {
    fetchPolicy: 'no-cache',
    skip: !searchAllWorkOps || loadingQueryFlow || editModeEnabled,

    onCompleted: (res) => {
      if (res.erpWorkOrderOperations) {
        if (searchAllWorkOps) {
          setAllWorkOrderOps(res.erpWorkOrderOperations);
        } else {
          setWorkOrderOps(res.erpWorkOrderOperations);
        }
        setLoadingMore(false);
        setPageLoading(false);
      }
    },
  });

  useQuery(GET_MACHINE_SCHEDULE, {
    variables: {
      machineId: machine.id,
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    skip: !machine.id || !editModeEnabled,
    onCompleted: ({ machineSchedule }) => {
      if (machineSchedule.schedule) {
        const machineValues = Object.values(machineSchedule.schedule)[0];
        const formatted = machineValues.upcoming.map((orderOp) => {
          return {
            ...orderOp,
            closedDate: null,
            finishQuantity: orderOp.quantityRequired,
            scheduledStartDate: orderOp.scheduledStartAt,
            sequenceNumber: orderOp.sequence,
            workOrder: {
              lot: orderOp.lot,
              partNumber: orderOp.partNumber,
              split: orderOp.split,
              workOrderId: orderOp.workOrderId,
              dueDate: orderOp.workOrderDueDate,
            },
            workOrderId: orderOp.workOrderId,
            workOrderOperationRef: orderOp.workOrderOperationRef,
            startQuantity: orderOp.partsProduced,
          };
        });

        setWorkOrderOps(formatted);
        setPageLoading(false);
      }
      return [];
    },
  });

  useQuery(GET_WORK_ORDER_OPERATIONS, {
    fetchPolicy: 'no-cache',
    skip: loadingCurrentWorkOrderOp || loadingQueryFlow || editModeEnabled,
    variables: {
      where: { ...whereClause, resourceId: { _in: machine.erpResources } },
    },
    onCompleted: (data) => {
      const workOrderOpRes = data.erpWorkOrderOperations;
      if (workOrderOpRes) {
        setWorkOrderOps(workOrderOpRes);
        let noResults;
        if (search) {
          noResults = searchOperations(search, workOrderOpRes)?.length === 0;
          if (noResults) {
            setPageLoading(true);
          }
        }
        if (workOrderOpRes.length && !noResults) {
          setPageLoading(false);
        } else {
          setSearchAllWorkOps(true);
          requestWorkOrderOperations({
            variables: {
              limit: 50,
              where: {
                ...whereClause,
                _or: [
                  { workOrderId: { _ilike: `%${search}%` } },
                  { workOrder: { partNumber: { _ilike: `%${search}%` } } },
                ],
              },
            },
          });
        }
      }
    },
  });

  const debouncedRequestAllWorkOps = debounce((value) => {
    return requestWorkOrderOperations({
      variables: {
        limit: 50,
        where: {
          ...whereClause,
          _or: [
            { workOrderId: { _ilike: `%${value}%` } },
            { workOrder: { partNumber: { _ilike: `%${value}%` } } },
          ],
        },
      },
    });
  }, 850);

  const filteredMachineWorkOps = useMemo(() => {
    return searchOperations(search, workOrderOps);
  }, [search, workOrderOps]);

  const filteredWorkOrderOps = searchAllWorkOps
    ? allWorkOrderOps
    : filteredMachineWorkOps;

  const handleCheckBoxOnChange = () => {
    if (searchAllWorkOps) {
      setAllWorkOrderOps(null);
    } else {
      setPageLoading(true);
      const where = editModeEnabled
        ? getAllWhereClause(currentWorkOrderOperationRef)
        : whereClause;

      requestWorkOrderOperations({
        variables: {
          where: {
            ...where,
            _or: [
              { workOrderId: { _ilike: `%${search}%` } },
              { workOrder: { partNumber: { _ilike: `%${search}%` } } },
            ],
          },
          limit: 50,
        },
      });
    }
    return setSearchAllWorkOps(!searchAllWorkOps);
  };

  const searchButtons = {
    searchAllMachines: {
      text: 'Search across all machines',
      onClick: handleCheckBoxOnChange,
    },
    searchAllOrders: {
      text: 'Search across all orders',
      onClick: () => {
        const nextQueryStep = editModeEnabled ? 2 : queryStep + 1;
        setQueryStep(nextQueryStep);
        setSearchAllWorkOps(false);
        setPageLoading(true);
        requestWorkOrderOperations({
          variables: {
            where: {
              ...getWhereClause(nextQueryStep),
              resourceId: { _in: machine.erpResources },
            },
          },
        });
      },
    },
    manualEnter: {
      text: 'Enter Manually',
      onClick: () => {
        mainDispatch(actionSetDefaultManualEntry(search));
        if (isUpdate) {
          return setOpenAddWorkOrderModal(true);
        }
        if (!hasOpenActivitySet) {
          return history.push(
            `${Routes.machineIdSelectJobPath(machine.id)}?manual=true`
          );
        }
        return setOpenStartNewRunModal(true);
      },
    },
  };

  const searchButtonKey =
    workOrderOps?.length && !searchAllWorkOps
      ? 'searchAllMachines'
      : queryStep !== 2
      ? 'searchAllOrders'
      : !laborTicketsEnabled
      ? 'manualEnter'
      : null;

  const searchButton = searchButtons[searchButtonKey];

  const headerText = getHeaderText({
    currentWorkOrderOp,
    queryStep,
    hasOpenActivitySet,
    latestJob,
  });

  const showEmptyMessage =
    error || pageLoading || !filteredWorkOrderOps?.length;

  return (
    <MainLayout.Container
      direction="column"
      overlay
      id="select-work-order-op"
      style={{ backgroundColor: palette.Grey100 }}
    >
      <AppNav
        iconColor={palette.Grey700}
        color={palette.White}
        onClose={handleOnClose}
      >
        <H5
          color={palette.Grey700}
          style={{
            width: '100%',
            textAlign: 'left',
            paddingLeft: '1.5rem',
            fontWeight: '500',
            marginLeft: '-8rem',
          }}
        >
          {headerText}
        </H5>
      </AppNav>

      <ControlsContainer>
        {openAddWorkOrderModal && (
          <AddWorkOrderModal
            forEditWorkOrderId
            handleOnClose={() => {
              return setOpenAddWorkOrderModal(false);
            }}
          />
        )}
        {openStartNewRunModal && (
          <AddWorkOrderModal
            isStopStartNewRun
            handleOnClose={() => {
              setOpenStartNewRunModal(false);
            }}
          />
        )}
        <SearchInput
          loading={loading && !pageLoading}
          inputProps={{
            value: search,
            onChange: ({ target: { value } }) => {
              if (searchAllWorkOps) {
                debouncedRequestAllWorkOps(value);
              }
              setSearch(value);
            },
            placeholder: _capitalize(t('search by keyword')),
          }}
        />
        <Checkbox
          inputProps={{
            id: 'searchAllOpenWorkOps',
            name: 'searchAllOpenWorkOps',
            checked: !searchAllWorkOps,
            onChange: handleCheckBoxOnChange,
          }}
          labelProps={{
            style: {
              fontSize: '0.875rem',
            },
          }}
        >
          Only show work scheduled for this machine
        </Checkbox>
      </ControlsContainer>
      {showEmptyMessage ? (
        <EmptyMessage
          loading={pageLoading}
          error={error}
          search={search}
          matchingPartOperation={queryStep === 1 && !editModeEnabled}
          SearchButton={
            searchButton && (
              <Button onClick={searchButton.onClick}>
                {searchButton.text}
              </Button>
            )
          }
        />
      ) : (
        <div
          style={{
            overflow: 'auto',
            display: 'grid',
            backgroundColor: palette.Grey100,
          }}
        >
          <WorkOrderContainer>
            {currentWorkOrderOp && (
              <WorkOrderCard
                active
                workOrderOp={currentWorkOrderOp}
                nextStep={nextStep}
                dispatch={dispatch}
                key={`${currentWorkOrderOp.workOrderId}-${currentWorkOrderOp.partNumber}-${currentWorkOrderOp.sequenceNumber}`}
              />
            )}
            {filteredWorkOrderOps.map((workOrderOp) => {
              const { workOrder } = workOrderOp;

              const schedule = [...workOrderOps];

              const blockingOperation =
                editModeEnabled &&
                schedule.find((operation) => {
                  return (
                    operation.workOrderId === workOrderOp.workOrderId &&
                    operation.sequenceNumber < workOrderOp.sequenceNumber
                  );
                });

              return (
                <WorkOrderCard
                  isBlockedBySequence={editModeEnabled && !!blockingOperation}
                  workOrderOp={workOrderOp}
                  nextStep={nextStep}
                  dispatch={dispatch}
                  key={`${workOrder.workOrderId}-${workOrder.partNumber}-${workOrderOp.sequenceNumber}`}
                />
              );
            })}
          </WorkOrderContainer>
          {searchAllWorkOps && (
            <LoadMoreButton
              onClick={(event) => {
                event.currentTarget.focus();
                setLoadingMore(true);
                return requestWorkOrderOperations({
                  variables: {
                    where: {
                      ...whereClause,
                      _or: [
                        { workOrderId: { _ilike: `%${search}%` } },
                        {
                          workOrder: {
                            partNumber: { _ilike: `%${search}%` },
                          },
                        },
                      ],
                    },
                    limit: limit + 50,
                  },
                });
              }}
              disabled={loadingMore || limit > allWorkOrderOps?.length}
              rounded
            >
              {loadingMore && (
                <LoadingCircle
                  icon={spinner}
                  size="20px"
                  color={palette.White}
                />
              )}
              Load more
            </LoadMoreButton>
          )}
        </div>
      )}
    </MainLayout.Container>
  );
};

SelectWorkOrderOp.propTypes = {
  handleOnClose: PropTypes.func,
  nextStep: PropTypes.func,
  dispatch: PropTypes.func,
};

export default SelectWorkOrderOp;
