import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import styled from 'styled-components';
import { CounterBlock } from '@m12s/component-library';

import { formatValue } from 'lib/utils/text';
import {
  getCurrentWorkOrderOp,
  // getCurrentWorkOrderOpCompletionDelta,
  // getCurrentWorkOrderOpCompletionDeltaText,
  getCurrentWorkOrderOpTimeToComplete,
} from 'lib/selectors/getCurrentWorkOrderOp';
import { getLatestActivitySet } from 'lib/selectors/getLatestActivitySet';

import { CounterLabel, WheelCounterPadding } from 'components/WheelLayout';
import { LoadingTextPlaceholder } from 'components/LoadingTextPlaceholder';
import { TextMedia } from 'pages/Machine/styled';
// import { Small } from 'components/Text';

const LoadingCounterValue = styled(LoadingTextPlaceholder)`
  height: 2rem;
  width: 90%;
  border-radius: 2px;
`;

// const SubValue = styled(Small)`
//   display: block;
//   font-size: 0.875rem;
//   font-weight: 400;
// `;

const BottomRight = () => {
  const currentWorkOrderOp = useSelector(getCurrentWorkOrderOp);
  const activitySet = useSelector(getLatestActivitySet);
  const timeToComplete = useSelector(getCurrentWorkOrderOpTimeToComplete);
  // const completionDeltaText = useSelector(
  //   getCurrentWorkOrderOpCompletionDeltaText
  // );
  // const completionDelta = useSelector(getCurrentWorkOrderOpCompletionDelta);

  const noCycleTimeEst = !(
    activitySet?.localMedianUnitDuration ||
    activitySet?.expectedUnitDuration ||
    currentWorkOrderOp.finishQuantity
  );

  // const isEarly = completionDelta.asSeconds() <= 0;

  return (
    <WheelCounterPadding bottom right>
      <CounterBlock
        label={
          <CounterLabel>
            <TextMedia min={40}>Est. Time Remaining</TextMedia>
            <TextMedia max={40}>Est. Remaining</TextMedia>
          </CounterLabel>
        }
        value={
          !currentWorkOrderOp ? (
            <LoadingCounterValue />
          ) : noCycleTimeEst ? (
            '-'
          ) : (
            <div>
              {moment.duration(timeToComplete).format('h[h] m[m]')}
              {/* TODO larger design / logic discussion needed of if this delta makes sense  + currently do not have access to ETTC */}
              {/* <SubValue
                style={{
                  color: isEarly ? palette.Green600 : palette.Red600,
                }}
              >
                {completionDeltaText}
              </SubValue> */}
            </div>
          )
        }
        align="right"
      />
    </WheelCounterPadding>
  );
};

const BottomLeft = () => {
  const currentWorkOrderOp = useSelector(getCurrentWorkOrderOp);

  return (
    <WheelCounterPadding bottom left>
      <CounterBlock
        label={
          <CounterLabel>
            <TextMedia min={40}>Order Progress</TextMedia>
            <TextMedia max={40}>Progress</TextMedia>
          </CounterLabel>
        }
        value={
          !currentWorkOrderOp ? (
            <LoadingCounterValue />
          ) : (
            `${formatValue(
              currentWorkOrderOp.totalGoodParts
            )?.toLocaleString() || '-'} / ${
              currentWorkOrderOp.finishQuantity
                ? formatValue(
                    currentWorkOrderOp.finishQuantity
                  )?.toLocaleString()
                : '-'
            } `
          )
        }
        align="left"
      />
    </WheelCounterPadding>
  );
};

export default {
  BottomRight,
  BottomLeft,
};
