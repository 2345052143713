export const getByJobWhereClause = (job, currentWorkOrderOperationRef = 0) => {
  const jobName = job.name;
  const jobPart = job.part;
  const jobSequence = job.operation;

  return {
    closedDate: { _is_null: true },
    status: { _nin: ['C', 'X'] },
    workOrderOperationRef: { _neq: currentWorkOrderOperationRef },
    ...(jobSequence ? { sequenceNumber: { _eq: jobSequence } } : {}),
    workOrder: {
      part: {
        _or: [
          { partNumber: { _ilike: jobName || '' } },
          { partNumber: { _ilike: jobPart || '' } },
        ],
      },
    },
  };
};

export const getByPartWhereClause = (workOrderOperation, partOperationRef) => {
  return {
    closedDate: { _is_null: true },
    status: { _nin: ['C', 'X'] },
    sequenceNumber: { _eq: workOrderOperation.sequenceNumber },
    workOrderOperationRef: {
      _neq: workOrderOperation.workOrderOperationRef,
    },
    workOrder: {
      part: {
        partOperations: { partOperationRef: { _eq: partOperationRef } },
      },
    },
  };
};

export const getAllWhereClause = (workOrderOperationRef) => {
  return {
    closedDate: { _is_null: true },
    status: { _nin: ['C', 'X'] },
    workOrderOperationRef: { _neq: workOrderOperationRef },
  };
};

export const searchOperations = (search, operations) => {
  if (!search || !operations) {
    return operations;
  }
  const toLowerCaseSearch = search.toLowerCase();
  return operations.filter(({ workOrder }) => {
    const partNumber = workOrder.partNumber.toLowerCase();
    const operationId = workOrder.workOrderId.toLowerCase();
    return (
      partNumber.includes(toLowerCaseSearch) ||
      operationId.includes(toLowerCaseSearch)
    );
  });
};

export const getHeaderText = ({
  queryStep,
  currentWorkOrderOp,
  latestJob,
  hasOpenActivitySet,
}) => {
  const currentPartOperation = currentWorkOrderOp?.partOperation;

  if (queryStep === 1) {
    if (currentPartOperation) {
      return `Select a new order for "${currentPartOperation.partNumber} Op ${currentWorkOrderOp.sequenceNumber}"`;
    }
    if (hasOpenActivitySet && latestJob) {
      return `Select a new order for ${latestJob.name}`;
    }
  }
  if (queryStep === 2) {
    return 'What are you working on?';
  }
  return "What's next?";
};
