const THOUSAND = 1000;
const ONE_HUNDRED_THOUSAND = 100 * THOUSAND;
const MILLION = THOUSAND * 1000;
const BILLION = MILLION * 1000;

const getUnit = (quantity) => {
  if (quantity < ONE_HUNDRED_THOUSAND) {
    return null;
  }
  if (quantity < MILLION) {
    return 'K';
  }
  if (quantity < BILLION) {
    return 'M';
  }
  return 'B';
};

const ABBREVIATION_VALUE_MAP = {
  K: THOUSAND,
  M: MILLION,
  B: BILLION,
};

const abbreviate = (value, unit) => {
  if (!unit) {
    return value.toLocaleString();
  }

  const count = value / ABBREVIATION_VALUE_MAP[unit];
  const truncatedNumber = Math.floor(count * 10) / 10;

  return `${Number(truncatedNumber)}${unit}`;
};

export const abbreviateQuantity = (quantity) => {
  if (typeof quantity !== 'number') {
    return null;
  }

  const unit = getUnit(quantity);
  return abbreviate(quantity, unit);
};

const getPartsRemainingDuration = (operation) => {
  const partsRemaining = Math.max(
    (operation.finishQuantity || 0) - (operation.startQuantity || 0),
    0
  );
  return partsRemaining * (operation.cycleTimeMs || 1);
};

const getSetupTime = (operation, currentOperation) => {
  if (currentOperation?.partOperationRef !== operation.partOperationRef) {
    return operation.setupTimeMs || 0;
  }
  return 0;
};

export const getOperationDurationText = ({
  operation,
  currentWorkOrderOpTimeToComplete,
  currentOperation,
}) => {
  if (currentWorkOrderOpTimeToComplete) {
    return currentWorkOrderOpTimeToComplete;
  }

  const partsRemainingDuration = getPartsRemainingDuration(operation);
  const setupDuration = getSetupTime(operation, currentOperation);

  return partsRemainingDuration + setupDuration;
};
