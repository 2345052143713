import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import _capitalize from 'lodash/capitalize';
import {
  PopupButtonGroup,
  Button,
  Icon,
  palette,
} from '@m12s/component-library';
import moment from 'moment-timezone';

import { commentSolid } from 'lib/icons';
import { P } from 'components/Text';
import {
  Popup,
  Header,
  HeaderText,
  Body,
  Overlay,
} from 'components/Popup/styled';
import { MoreAlertMessage } from 'components/Popup/MoreAlertMessage';
import { getPopupStackLength } from 'lib/selectors/getPopupStackLength';
import { getCompany } from 'lib/selectors/getCompany';

const WorkflowTriggerPopupHeader = styled(Header)`
  height: auto;
  min-height: 2rem;
`;

const WorkflowPopupTriggered = ({ dismiss, workflowData }) => {
  const { t } = useTranslation();
  const {
    triggerDetails,
    message,
    triggerType,
    currentItemValue,
    timestamp,
  } = workflowData;
  const company = useSelector(getCompany);
  const tzid = _get(company, 'timezone');
  const popupLen = useSelector(getPopupStackLength);
  return (
    <>
      <Overlay />
      <Popup id="workflow-popup" moreThanOnePopup={popupLen > 1}>
        <WorkflowTriggerPopupHeader>
          <HeaderText>
            <Icon icon={commentSolid} />
            &nbsp;&nbsp;
            {t(`triggerDetailText|${triggerType}`, {
              ...triggerDetails,
              time: moment.tz(timestamp, tzid).format('h:mm A'),
              date: moment.tz(timestamp, tzid).format('MMMM D, YYYY'),
              ...(triggerType === 'conditionMonitor' && {
                currentItemValue,
              }),
            })}
          </HeaderText>
        </WorkflowTriggerPopupHeader>
        <Body>
          <P color={palette.Grey600}>
            {message}
            <br />
          </P>
          <br />
          <PopupButtonGroup>
            <Button
              onClick={() => {
                return dismiss(workflowData);
              }}
              id="dismiss"
              variant="inferior"
            >
              {_capitalize(t('dismiss'))}
            </Button>
          </PopupButtonGroup>
        </Body>
      </Popup>
      {popupLen > 1 && <MoreAlertMessage popupLen={popupLen} />}
    </>
  );
};

WorkflowPopupTriggered.propTypes = {
  dismiss: PropTypes.func.isRequired,
  workflowData: PropTypes.object.isRequired,
};

export { WorkflowPopupTriggered };
