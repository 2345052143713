import { createSelector } from 'reselect';
import { getWorkOrderManagement } from './getWorkOrderManagement';

const getLoadingCurrentWorkOrderOp = createSelector(
  [getWorkOrderManagement],
  (workOrderManagement) => {
    return workOrderManagement?.loadingCurrentWorkOrderOp;
  }
);

export { getLoadingCurrentWorkOrderOp };
