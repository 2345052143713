import { createSelector } from 'reselect';
import { getLatestActivitySet } from 'lib/selectors/getLatestActivitySet';
import moment from 'moment';
import { fromNow } from 'lib/utils/date';

import { getWorkOrderManagement } from './getWorkOrderManagement';
import { getLatestActivityMode } from './getLatestActivityMode';
import { getLatestActivity } from './getLatestActivity';

const getCurrentWorkOrderOp = createSelector(
  [getWorkOrderManagement],
  (workOrderManagement) => {
    return workOrderManagement?.currentWorkOrderOp;
  }
);

const getCurrentWorkOrderOpTotalGoodParts = createSelector(
  [getCurrentWorkOrderOp],
  (currentWorkOrderOp) => {
    return currentWorkOrderOp?.totalGoodParts;
  }
);

const getUnitDuration = (activitySet, cycleTimeMs) => {
  return (
    moment
      .duration(
        activitySet?.localMedianUnitDuration ||
          activitySet?.expectedUnitDuration
      )
      .asMilliseconds() || cycleTimeMs / activitySet.actualParts
  );
};

const getCurrentWorkOrderOpCompletionDelta = createSelector(
  [getCurrentWorkOrderOp, getLatestActivitySet],
  (currentWorkOrderOp, latestActivitySet) => {
    if (currentWorkOrderOp) {
      const {
        cycleTimeMs,
        scheduledFinishDate,
        totalGoodParts,
        finishQuantity,
      } = currentWorkOrderOp;

      const partsRemaining = finishQuantity - totalGoodParts;
      const unitDuration = getUnitDuration(latestActivitySet, cycleTimeMs);
      const timeToComplete = Math.max(unitDuration * partsRemaining, 0);

      return moment.duration(fromNow(timeToComplete).diff(scheduledFinishDate));
    }
    return null;
  }
);

const getCompletionDeltaText = (completionDelta, isEarly) => {
  if (completionDelta < 1000 * 60) {
    return '✓ On time';
  }

  const formattedDuration = moment
    .duration(completionDelta)
    .format('h[h] m[m] s[s]', {
      largest: 2,
    });

  if (isEarly) {
    return `↑ ${formattedDuration} early`;
  }
  return `↓ ${formattedDuration} late`;
};

const getCurrentWorkOrderOpCompletionDeltaText = createSelector(
  [getCurrentWorkOrderOpCompletionDelta],
  (completionDelta) => {
    const isEarly = completionDelta.asSeconds() <= 0;
    return getCompletionDeltaText(completionDelta, isEarly);
  }
);

const getCurrentWorkOrderOpTimeToComplete = createSelector(
  [
    getCurrentWorkOrderOp,
    getLatestActivitySet,
    getLatestActivityMode,
    getLatestActivity,
  ],
  (
    currentWorkOrderOp,
    latestActivitySet,
    latestActivityMode,
    latestActivity
  ) => {
    let remainingSetupMs = 0;

    if (currentWorkOrderOp) {
      if (latestActivityMode === 'setup') {
        const timeInSetup = moment
          .duration(moment().diff(latestActivity.start))
          .valueOf();

        remainingSetupMs = Math.max(
          (currentWorkOrderOp.setupTimeMs || 0) - timeInSetup,
          0
        );
      }
      const {
        cycleTimeMs,
        totalGoodParts,
        finishQuantity,
      } = currentWorkOrderOp;

      const unitDuration = getUnitDuration(latestActivitySet, cycleTimeMs);
      const partsRemaining = finishQuantity - totalGoodParts;
      return Math.max(unitDuration * partsRemaining + remainingSetupMs, 0);
    }
    return null;
  }
);

const getCurrentWorkOrderOpStartAt = createSelector(
  [getCurrentWorkOrderOp],
  (currentWorkOrderOp) => {
    return currentWorkOrderOp?.startAt;
  }
);

export {
  getCurrentWorkOrderOp,
  getCurrentWorkOrderOpTotalGoodParts,
  getCurrentWorkOrderOpStartAt,
  getCurrentWorkOrderOpTimeToComplete,
  getCurrentWorkOrderOpCompletionDeltaText,
  getCurrentWorkOrderOpCompletionDelta,
};
