import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Loading } from 'components/Loading';
import NoResults from 'graphics/NoResults';
import { Text } from 'components/Text';

const CenteredMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
  gap: 1.5rem;
`;

const EmptyMessage = ({
  loading,
  error,
  search,
  SearchButton,
  matchingPartOperation,
}) => {
  if (loading) {
    return <Loading />;
  }
  if (error) {
    return (
      <CenteredMessage>Error loading scheduled operations</CenteredMessage>
    );
  }

  return (
    <CenteredMessage>
      <div
        style={{
          maxWidth: '20rem',
          textAlign: 'center',
          display: 'grid',
          placeItems: 'center',
        }}
      >
        <NoResults />
        <Text style={{ fontWeight: '600', marginTop: '1.25rem' }}>
          {search ? 'No results found' : 'No scheduled operations found'}
        </Text>
        <Text style={{ fontSize: '.875rem' }}>
          {search
            ? `No operations found matching "${search}". \nPlease
              verify the entry and try again.`
            : `There were no scheduled production order operations found${
                matchingPartOperation
                  ? ' for this part operation'
                  : ' for this machine'
              }.`}
        </Text>
      </div>
      {SearchButton || <></>}
    </CenteredMessage>
  );
};

EmptyMessage.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.object,
  search: PropTypes.string,
  SearchButton: PropTypes.node,
  matchingPartOperation: PropTypes.bool,
};

export default EmptyMessage;
