import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Icon,
  // , palette
} from '@m12s/component-library';
import { faEdit } from '@fortawesome/pro-regular-svg-icons';
import routes from 'lib/utils/routes';
import moment from 'moment';
import { fromNow } from 'lib/utils/date';

import { getMachine } from 'lib/selectors/getMachine';
import {
  getCurrentWorkOrderOp,
  // getCurrentWorkOrderOpCompletionDelta,
  // getCurrentWorkOrderOpCompletionDeltaText,
  getCurrentWorkOrderOpTimeToComplete,
} from 'lib/selectors/getCurrentWorkOrderOp';

import {
  OpButton,
  TopCard,
  BottomCardContainer,
  InfoGroup,
  Divider,
} from './styled';
import { withNotApplicableFallback } from './helpers';

const formatDate = (timestamp) => {
  const momentTimestamp = moment(timestamp);

  if (momentTimestamp.isSame(moment(), 'day')) {
    return `Today, ${momentTimestamp.format('LT')}`;
  }
  return momentTimestamp.format('lll');
};

const Top = () => {
  const history = useHistory();
  const machine = useSelector(getMachine);
  const currentWorkOrderOp = useSelector(getCurrentWorkOrderOp);

  // const completionDelta = useSelector(getCurrentWorkOrderOpCompletionDelta);
  // const completionDeltaText = useSelector(
  //   getCurrentWorkOrderOpCompletionDeltaText
  // );
  const timeToComplete = useSelector(getCurrentWorkOrderOpTimeToComplete);

  const { finishQuantity, workOrder, totalGoodParts } =
    currentWorkOrderOp || {};

  const partsRemaining = finishQuantity - totalGoodParts;
  // const isEarly = completionDelta.asSeconds() <= 0;

  return (
    <>
      <TopCard
        label="Production Order Operation"
        value={workOrder?.workOrderId || 'None'}
        subValue={
          <OpButton
            onClick={() => {
              return history.push(
                `${routes.machineIdSelectJobPath(
                  machine.id
                )}?updateWorkOrderId=true`
              );
            }}
          >
            <Icon
              icon={faEdit}
              size="0.75rem"
              style={{ marginRight: '0.375rem' }}
            />
            Change Operation
          </OpButton>
        }
      />
      <TopCard
        label="Parts Remaining"
        value={Math.max(partsRemaining || 0, 0)?.toLocaleString()}
        subValue={`${totalGoodParts?.toLocaleString() ||
          '-'} / ${finishQuantity?.toLocaleString()}`}
      />
      <TopCard
        label="Est. Completion Date"
        value={
          totalGoodParts >= finishQuantity
            ? 'Complete'
            : formatDate(fromNow(timeToComplete))
        }
        // TODO larger design / logic discussion needed of if this delta makes sense + currently do not have access to ETTC
        // subValue={
        //   <span
        //     style={{
        //       color: isEarly ? palette.Green600 : palette.Red600,
        //       fontSize: '0.75rem',
        //       fontWeight: '400',
        //     }}
        //   >
        //     {completionDeltaText}
        //   </span>
        // }
      />
    </>
  );
};

const Bottom = () => {
  const currentWorkOrderOp = useSelector(getCurrentWorkOrderOp);
  const timeToComplete = useSelector(getCurrentWorkOrderOpTimeToComplete);

  const { scheduledStartDate, workOrder, startAt } = currentWorkOrderOp || {};

  return (
    <BottomCardContainer>
      <InfoGroup
        top={{
          label: 'Part Operation',
          value: withNotApplicableFallback(
            workOrder.partNumber &&
              `${workOrder.partNumber} Op ${currentWorkOrderOp.sequenceNumber}`
          ),
        }}
        bottom={[
          {
            label: 'Lot',
            value: withNotApplicableFallback(workOrder.lot),
          },
          {
            label: 'Split',
            value: withNotApplicableFallback(workOrder.split),
          },
          {
            label: 'Sub',
            value: withNotApplicableFallback(workOrder.sub),
          },
        ]}
      />
      <Divider />
      <InfoGroup
        top={{
          label: 'PO Due Date',
          value: formatDate(workOrder.dueDate),
        }}
        bottom={{
          label: 'Estimated Time Remaining',
          value: `${moment.duration(timeToComplete).format('h[h] m[m]', {
            largest: 2,
          }) || '0m'}`,
        }}
      />
      <Divider />
      <InfoGroup
        top={{
          label: 'Planned Start Date',
          value: formatDate(scheduledStartDate),
        }}
        bottom={{
          label: 'Actual Start Date',
          value: formatDate(startAt),
        }}
      />
    </BottomCardContainer>
  );
};

export default () => {
  return (
    <>
      <Top />
      <Bottom />
    </>
  );
};
